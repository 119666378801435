<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-5">
            <CCol md="12" class="p-0 ">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
                    <h2 class="text-center text-white middle-center"><b>Laporan Permohonan <br><span class="text-success">Selesai</span></b></h2>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard>
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h3 class="mb-0 text-center">Laporan Permohonan Selesai</h3>
                        </CCardHeader>
                        <CCardBody>
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <b for="">Tgl Dari</b>
                                            <vuejs-datepicker input-class="form-control no-readonly" v-model="start" format="dd MMM yyyy"></vuejs-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group">
                                            <b for="">Tgl Sampai</b> 
                                            <vuejs-datepicker input-class="form-control no-readonly" v-model="end" format="dd MMM yyyy"></vuejs-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <b for="">Tipe Permohonan</b>
                                            <v-select :options="filter_tipe" :reduce="label => label.value" label="label" v-model="is_pemasukan" :value="$store.myValue"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <div class="form-group">
                                            <b for="">Status</b>
                                            <v-select :options="filter_status" :reduce="label => label.value" label="label" v-model="status":value="$store.myValue"></v-select>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div style="margin-top: 27px;">
                                    <div class="btn btn-sm btn-primary" v-on:click="toPage(apiLink + 'api/laporan/permohonan_selesai')"><i class="fa fa-filter"></i> Filter</div>
                                    <div class="btn btn-sm btn-success pull-right" v-on:click="getExcel"><i class="fa fa-download"></i> Excel</div>
                                </div>
                            </div>
                        </div>
                        <CRow>
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="12">
                                        <table class="table table-sm table-bordered">
                                            <thead class="bg-dark text-white">
                                                <tr>
                                                    <th class="align-top" rowspan="2">No</th>
                                                    <th>Kode Permohonan</th>
                                                    <th>Kategori Module</th>
                                                    <th>Nomor Surat</th>
                                                    <th>Tgl Pengajuan</th>
                                                    <th>Tgl Selesai</th>
                                                </tr>
                                                <tr>
                                                    <td><input type="text" class="form-control"></td>
                                                    <td><input type="text" class="form-control"></td>
                                                    <td><input type="text" class="form-control"></td>
                                                    <td><input type="text" class="form-control"></td>
                                                    <td><input type="text" class="form-control"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template>
                                                    <tr v-if="loadingTable">
                                                        <td colspan="11"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                    </tr>
                                                    <tr v-for="(listlaporan, key_laporan) in reslistlaporan" :key="key_laporan" v-else>
                                                        <td>{{key_laporan+1}}</td>
                                                        <td>{{listlaporan.kode}}</td>
                                                        <td>{{listlaporan.module}}</td>
                                                        <td>{{listlaporan.pr_nomor_surat}}</td>
                                                        <td>{{listlaporan.pr_tanggal}}</td>
                                                        <td>{{listlaporan.pr_tanggal_selesai}}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <nav aria-label="pagination">
                                            <ul class="pagination justify-content-start">
                                                <!---->
                                                <!-- {{ page.active ? 'active' : '' }} -->
                                                <li v-for="(page, key_page) in paging" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
                                                    <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                        <div v-html="page.label"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <br />
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
    const datalaporan = [];
    export default {
        name: "LaporanPemohonSelesai",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                reslistlaporan: datalaporan,
                paging: [],
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                filter_status: [
                    {
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Varietas",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Varietas",
                    },
                ],
                curent_page: '',
                loadingTable: true,
            };
        },
        methods: {
            toPage(url) {
                this.loadingTable = true;
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                axios
                    .get(url, {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_laporan = response.data;
                        var res_laporan_data = res_laporan.data;
                        // console.log(res_laporan_data);
                        if (res_laporan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_laporan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.reslistlaporan = res_laporan_data.list.data;
                            this.paging = res_laporan_data.list.links;
                            this.show_data_from = res_laporan_data.from;
                            this.show_data_to = res_laporan_data.list.to;
                            this.total_data = res_laporan_data.list.total;
                            this.curent_page = res_laporan_data.list.current_page;
                        }
                    });
            },
            getExcel(){
                var win = window.open(this.excel_url, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
            }
        },
        beforeCreate() {
            let session_data = JSON.parse(this.session);
            axios
                .get(this.apiLink + "api/laporan/permohonan_selesai", {
                    params:{
                        is_pemasukan: this.is_pemasukan,
                        start: this.start,
                        end: this.end,
                        status: this.status,
                        nib: session_data.nib,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_laporan = response.data;
                    var res_laporan_data = res_laporan.data;
                    // console.log(res_laporan_data);
                    if (res_laporan.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_laporan.data.message,
                        });
                    } else {
                        this.loadingTable = false;
                        this.excel_url = res_laporan_data.excel;
                        this.reslistlaporan = res_laporan_data.list.data;
                        this.paging = res_laporan_data.list.links;
                        this.show_data_from = res_laporan_data.from;
                        this.show_data_to = res_laporan_data.list.to;
                        this.total_data = res_laporan_data.list.total;
                        this.curent_page = res_laporan_data.list.current_page;
                    }
                });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>